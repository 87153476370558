<template>
  <div class="activity-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>類別管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          類別列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <el-button
          type="success"
          @click="$router.push({ name: 'CreateActivityCategory' })"
          >新增類別
        </el-button>
      </table-title>

      <el-table :data="category_data">
        <el-table-column
          label="ID"
          prop="category_id"
          width="100"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="260">
          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="
                $router.push({
                  name: 'DetailActivityCategory',
                  params: { id: row['category_id'] },
                })
              "
              >內容</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditActivityCategory',
                  params: { id: row['category_id'] },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row['category_id'])"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  deleteActivityCategory,
  getActivityCategoriesPagination,
} from "@/api/activity-category";

export default {
  name: "ActivityCategory",
  data() {
    return {
      category_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
      },
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }
    this.handleGetCategories();
  },
  methods: {
    async handleGetCategories() {
      const { results, total } = await getActivityCategoriesPagination(
        this.setup
      );

      this.category_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetCategories();
    },
    handleDelete(category_id) {
      this.$confirm("是否刪除該類別", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteActivityCategory(category_id);
          await this.handleGetCategories();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
