var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-category-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',{attrs:{"to":{ name: 'Activity' }}},[_vm._v("活動管理 ")]),_c('el-breadcrumb-item',[_vm._v("類別管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 類別列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateActivityCategory' })}}},[_vm._v("新增類別 ")])],1),_c('el-table',{attrs:{"data":_vm.category_data}},[_c('el-table-column',{attrs:{"label":"ID","prop":"category_id","width":"100","sortable":""}}),_c('el-table-column',{attrs:{"label":"中文名稱","prop":"name_zh","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文名稱","prop":"name_en","sortable":""}}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-setting"},on:{"click":function($event){return _vm.$router.push({
                name: 'DetailActivityCategory',
                params: { id: row['category_id'] },
              })}}},[_vm._v("內容")]),_c('el-button',{attrs:{"size":"mini","type":"warning","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditActivityCategory',
                params: { id: row['category_id'] },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row['category_id'])}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }